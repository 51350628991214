@import "../../styles/variables.scss";

.container {
  @include contentCenter;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
}

.innerContainer {
  width: 100%;
  max-height: 100vh;
  overflow: auto;
}

.logo {
  margin: 20px;
  width: 70px;
  height: 70px;
}
.callImage {
  width: 70px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.thanksText {
  font-size: 24px;
  color: #ffac33;
  font-weight: 600;
}

.queueText {
  @include regularText;
  font-size: $regularFont;
  color: #111111;
  font-size: 24px;
  font-weight: 400;
  margin-top: 20px;
}

.pleaseWaitText {
  width: 90%;
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  color: #111111;
}

.addButton {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.outletLogo {
  margin-top: 20%;
}

@media #{$media} and ($maxWidth: $sm) {
  .thanksText,
  .queueText {
    font-size: 18px;
  }
  .pleaseWaitText {
    font-size: 13px;
  }
}
