@import "../../styles/variables.scss";

.buttonContainer {
  width: 100%;
  max-width: 900px;
  margin: auto;
  @include contentCenter;
  padding: 0 $regularPadding;
  height: 60px;
  border-radius: 4px;
  cursor: pointer;
  transition-duration: 0.2s;
  font-family: "Poppins", serif !important;
}
.greyedButtonContainer {
  background: #eeeeee;
  border: 1px solid #979797;
  border-radius: 8px;
}
.filledButtonContainer {
  background-color: $buttonColor;
  color: $buttonFontColor;
  border: 1px solid $buttonColor;
  &:hover {
    background-color: $buttonColor;
  }
  &:active {
    z-index: 1;
    background-color: $buttonColor;
    box-shadow: 0 0 0 1px $textWhite, 0 0 0 2.5px $buttonColor;
  }
}

.whiteButtonContainer {
  background-color: $bgWhite;
  color: $buttonColor;
  &:hover {
    background-color: $bgWhite;
  }
  &:active {
    z-index: 1;
    background-color: $bgLtGray;
    box-shadow: 0 0 0 1px $bgWhite, 0 0 0 2.5px $bgLtGray;
  }
}
.outlinedButtonContainer {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  // &:hover {
  //   background-color: $bgWhite;
  // }
  // &:active {
  //   z-index: 1;
  //   background-color: $bgLtGray;
  //   box-shadow: 0 0 0 1px $bgWhite, 0 0 0 2.5px $bgLtGray;
  // }
}
.filledOpaqueButtonContainer {
  background-color: $buttonColor;
  color: $buttonFontColor;
  &:hover {
    background-color: $buttonColor;
  }
  &:active {
    z-index: 1;
    background-color: $buttonColor;
    box-shadow: 0 0 0 1px $textWhite, 0 0 0 2.5px $buttonColor;
  }
}
.filledOpaqueDisabledButtonContainer {
  cursor: not-allowed;
  background-color: $buttonColor;
  opacity: 0.2;
}
.filledDisabledButtonContainer {
  cursor: not-allowed;
  background-color: $buttonColor;
  opacity: 0.2;
  &:hover {
    background-color: $buttonColor;
    opacity: 0.2;
  }
  &:active {
    z-index: 1;
    background-color: $buttonColor;
    opacity: 0.2;
    box-shadow: none;
  }
}
.whiteDisabledButtonContainer {
  cursor: not-allowed;
  background-color: $bgWhite;
  color: $bgDkGray !important;
  &:hover {
    background-color: $bgWhite;
  }
  &:active {
    z-index: 1;
    background-color: $bgLtGray;
    box-shadow: 0 0 0 1px $bgWhite, 0 0 0 2.5px $bgWhite;
  }
}
.filledButtonText {
  @include boldText;
  font-size: $largeFont;
  letter-spacing: 0em;
  text-align: center;
  color: black;
}
.greyedButtonText {
  @include boldText;
  font-size: $largeFont;
  letter-spacing: 0em;
  text-align: center;
  color: black;
}

.transparentButtonText {
  @include boldText;
  color: $textBlue;
  font-size: $largeFont;
  line-height: $regularFont;
}

.transparentButtonContainer {
  background-color: transparent;
  border: none;
  border-radius: 500px; // to give a capsule shape style

  @include contentCenter;
  padding: $xsmallPadding;
  margin: $xsmallMargin;

  transition-duration: 0.5s;
  cursor: pointer;
}

.transparentButtonContainer:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.buttonIcon {
  overflow: hidden;
  color: $textBlack; //for black svg icons(unless specified)
}
