@import "../../styles/variables.scss";

.labelContainer {
  @include contentCenter;
  padding-bottom: 15px;
  font-family: "Poppins", serif !important;
}
.backArrow {
  margin-right: 10px;
}
.textInputLabel {
  @include regularText;
  font-size: $mediumFont;
  margin: $xsmallMargin;
  display: inline-block;
  text-align: left;
  width: 100%;
  padding-bottom: 15px;
  font-size: 32px;
  font-weight: 400;
  color: #111111;
  font-family: "Poppins", serif !important;
}

.errorText {
  color: $errorText;
  font-size: $mediumFont;
  margin-top: 5px;
  font-family: "Poppins", serif !important;
}

.container {
  width: 100%;
  font-family: "Poppins", serif !important;

  input {
    border: 2px solid #ffac33;
    border-radius: 4px;
    height: 70px;
    padding: 0 7px;
    font-family: "Poppins", serif !important;
    font-size: 24px !important;
    font-weight: 500 !important;
  }
}

@media #{$media} and ($maxWidth: $sm) {
  .textInputLabel {
    font-size: 20px;
  }
  .container {
    input {
      font-size: 16px !important;
    }
  }
}
