@import "../../styles/variables.scss";

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.firstContainer {
  @include contentCenter;
  flex-direction: column;
  width: 100%;
  height: 50%;
  background: white;
}
.endContainer {
  width: 100%;
  height: 100%;
  // background: $primaryColor;
  background: white;
  overflow: auto;
}
.logo {
  margin-top: 15px;
  width: 140px;
  height: 170px;
}
.mainLogo {
  @include contentCenter;
  flex-direction: column;
}
.outletLogo {
  // width: 45%;
  display: inline-block;
  // border-radius: 24px;
  object-fit: contain;
  // margin-top: 40px;
}
.welcomeText {
  @include boldText;
  color: #000000;
  font-size: 32px;
  font-weight: 600;
}
.name {
  width: 50%;
  margin-top: 20px;
}

.walkinButton {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 20px;
}
.footerText {
  @include regularText;
  position: fixed;
  bottom: 10px;
  color: #000000;
  font-size: 20px;
  margin: auto;
  left: 0;
  right: 0;

  span {
    color: #ffac33;
    font-weight: 600;
  }
}
.logout {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.mainOuter {
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media #{$media} and ($maxWidth: $sm) {
  .welcomeText {
    font-size: 20px;
  }
  .walkinButton {
    font-size: 16px;
  }
  .footerText {
    font-size: 14px;
  }
}
