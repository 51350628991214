.progress_bar_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 30px;
}

.items_progress {
  display: flex;
  justify-content: space-between; /* Distribute items evenly across the full width */
  width: 100%; /* Make sure it takes the full available width */
  align-items: center;
}
.occasion_step {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white; /* Default */
  border: 2px solid #d1d5db;
}

.occasion_step.active {
  background-color: #ffac33;
  border: 2px solid #ffac33; /* Completed */
}

.occasion_step.current {
  background-color: white; /* Current step */
  border: 2px solid #ffac33; /* Current step border */
}

.innerActiveCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffac33;
}

.occasion_bar {
  height: 2px;
  background-color: #d1d5db;
  flex-grow: 1;
}

.innerRightIcon {
  background: #ffac33;
}

.occasion_bar.active,
.occasion_bar.current {
  background-color: #ffac33; /* Completed bar and Current bar */
}
