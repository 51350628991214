@import "../../styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Poppins", serif !important;
}

.buttonContainer {
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 20%;
}

.buttonBody {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: max-content;
  gap: 6px;
  cursor: pointer;
}

.fwdIconContainer {
  background: black;
  margin: auto;
  padding: 16px 30px;
  border-radius: 50%;
}

.nextBtnLabel {
  font-size: 24px;
  font-weight: 600;
}


@media #{$media} and ($maxWidth: $sm) {
  .buttonContainer {
    bottom: 15%;
  }
}
