@import "../../styles/variables.scss";

.customScroll {
  @include customScrollBar;
}
.container {
  width: 420px;
  max-width: 100%;
  @media #{$media} and ($maxWidth: $sm) {
    width: 100vw;
    min-width: 100%;
  }
}
