@import "../../styles/variables.scss";

.buttonContainer {
  width: 100%;
  position: fixed;
  bottom: 2%;
  left: 0;
}
.buttonContainer span {
  font-size: 32px !important;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.topContainer {
  display: flex;
  flex-direction: column;
}
.labelText {
  @include regularText;
  font-size: 32px;
  font-weight: 400;
  color: #111111;
  text-align: left;
  margin-bottom: 10px;
}
.lowerContainer {
  background: #fffaf4;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  border-radius: 24px;
}
.userDetailsContainer {
  @include contentCenter;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
}
.userDetailsTitle {
  @include regularText;
  font-size: $regularFont;
  color: black;
}
.userDetailsDesc {
  @include boldText;
  font-size: $mediumFont;
  word-break: break-all;
}
.prefContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 10px;
}
@mixin preference {
  @include regularText;
  font-size: 18px;
  padding: 10px;
  border-radius: 8px;
  margin: 4px;
  width: calc(25% - 8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 80px;
}
.unSelectedPreference {
  @include preference;
  background: #fff;
  color: #979797;
  font-weight: 400;
  border: 2px solid #c1c7cf;
}
.selectedPreference {
  @include preference;
  font-weight: 500;
  background: #fffaf4;
  border: 2px solid #ffac33;
  color: #626262;
}
.alertContainer {
  width: 300px;
  height: 200px;
  padding: 15px;
  padding-bottom: 0px;
}
.prefIcon {
  height: 25px;
  margin-bottom: 5px;
}

@media #{$media} and ($maxWidth: $sm) {
  .buttonContainer span {
    font-size: 16px !important;
  }
}
