@import "../../styles/variables.scss";

.otpMain {
  width: 100%;
}
.heading {
  @include boldText;
  font-size: $largeFont;
  margin-bottom: 10px;
  color:white
}

.verifyImg {
  margin-bottom: 20px;
}
.otpBox {
  width: 100%;
  margin-top: 40px;
}
.timerContainer {
  font-size: $regularFont;
  margin-top: 30px;
  margin-bottom: 20px;
  color:white
}
.subHeading {
  font-size: $regularFont;
  font-weight: $mediumWeight;
  color:white
}

.resendOtpActive {
  font-size: $regularFont;
  color: $textBlue;
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
}

.backButton {
  @include boldText;
  font-size: $largeFont;
  color: $textBlue;
  cursor: pointer;
  margin-top: 40px;
}
.emailHeading {
  @include boldText;
  font-size: $mediumFont;
}
.errorText {
  color: #d32b36;
  font-size: $regularFont;
  align-self: baseline;
  margin-top: 5px;
}
.successText {
  color: green;
  font-size: $regularFont;
  align-self: baseline;
  margin-top: 10px;
}
