@import "../../styles/variables.scss";

.labelContainer {
  @include contentCenter;
  justify-content: flex-start;
  font-family: "Poppins", serif !important;
}
.backArrow {
  margin-right: 10px;
}

.container {
  width: 100%;
  overflow: auto;
  display: flex;
  font-family: "Poppins", serif !important;
}
.guestPicker {
  @include contentCenter;
  font-size: 32px;
  font-weight: 500;
  height: 50px;
  width: 50px;
  margin: 6px;
  background: #ffffff;
  border-radius: 4px;
  color: #626262;
  cursor: pointer;
  flex-shrink: 0;
  border: 2px solid #c1c7cf;
  font-family: "Poppins", serif !important;
}
.selected {
  background: #fffaf4;
  color: #626262;
  font-weight: 700;
  border: 2px solid #ffac33;
}
.errorMessage {
  @include regularText;
  color: $errorText;
  margin-top: 10px;
  font-size: $regularFont;
  font-family: "Poppins", serif !important;
}
.guestInput {
  background: #fffaf4;
  border: none;
  border-radius: 10px;
  width: 170px;
  height: 60px;
  margin-left: 5px;
  font-size: 24px;
  font-weight: 500;
  padding-left: 20px;
  margin-top: 16px;
  font-family: "Poppins", serif !important;
  outline: none;
}

.labelText {
  @include regularText;
  margin: $xsmallMargin;
  display: inline-block;
  text-align: left;
  width: 100%;
  margin-bottom: 11px;
  font-size: 32px;
  font-weight: 400;
  color: #111111;
  font-family: "Poppins", serif !important;
}
.guestInputContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: "Poppins", serif !important;
}
.extraGuestLabel {
  @include regularText;
  font-size: $mediumFont;
  padding-bottom: 5px;
  margin: $xsmallMargin;
  display: inline-block;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  color: #111111;
  font-size: 32px;
  font-weight: 400;
  font-family: "Poppins", serif !important;
}

@media #{$media} and ($maxWidth: $sm) {
  .labelText,
  .guestPicker,
  .extraGuestLabel {
    font-size: 20px;
  }
  .guestInput {
    font-size: 16px;
  }
}
