////// Primary color from theme config //////
$primaryColor: var(--primary-color);
$secondaryColor: var(--secondary-color);
$defaultColor: #000000;
$buttonColor: var(--secondary-color);
$buttonFontColor: #ffffff;
$headerColor: #000000;
$headerFontColor: #ffac33;

//// Background
$bgWhite: #fff;
$bgGray: #ccc;
$bgLtGray: #f5f5f5;
$bgDkGray: #ddd;

//// Text colors variables
$textBlack: #000;
$textPrimary: #000000;
$textSecondary: #878787;
$textTertiary: #999999;
$textBlue: #068ad3;
$textWhite: #fff;
$textGrey: #c2c2c2;
$textDkGray: #111111;
$errorText: $secondaryColor;

//// Font size
$xSmallFont: 0.5rem; //8px
$smallFont: 0.625rem; //10px;
$regularFont: 0.75rem; //12px
$mediumFont: 0.875rem; //14px
$largeFont: 1rem; //16px
$xlargeFont: 1.125rem; //18px
$xxlargeFont: 1.5rem; //24px

@media only screen and (min-width: 558px) and (max-width: 650px) {
  $xSmallFont: 0.75rem; //8px
  $smallFont: 0.9375rem; //10px;
  $regularFont: 1.125rem; //12px
  $mediumFont: 1.312rem; //14px
  $largeFont: 1.5rem; //16px
  $xlargeFont: 1.687rem; //18px
  $xxlargeFont: 2.25rem; //24px
}

//// Font weight
$normalWeight: 400;
$mediumWeight: 700;
$heavyWeight: 900;

// Padding
$xsmallPadding: 5px;
$smallPadding: 10px;
$regularPadding: 15px;
$mediumPadding: 20px;
$largePadding: 25px;

//Margin
$xsmallMargin: 5px;
$smallMargin: 10px;
$regularMargin: 15px;
$mediumMargin: 20px;
$largeMargin: 25px;

@mixin regularText {
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0em;
  font-display: swap;
  color: white;
}
@mixin boldText {
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0em;
  font-display: swap;
}

@mixin contentCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin outlinedButton {
  border: 1px solid $buttonColor;
  color: $buttonColor;
  outline: none;
  background: #ffffff;
}
@mixin solidButton {
  background: $buttonColor;
  color: $buttonFontColor;
  outline: none;
}

@mixin singleLineTextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

@mixin threeLineTextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

@mixin card {
  background-color: $bgWhite;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

//media Query
$minWidth: min-width;
$maxWidth: max-width;
$media: screen;
$xs: "420px";
$sm: "767px";
$lg: "1200px";

@mixin customScrollBar {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(224, 224, 224);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(184, 184, 184);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(184, 184, 184);
  }
}
