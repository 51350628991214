@import "../../styles/variables.scss";

.reserveContainer {
  // background-color: $primaryColor;
  background: #fff;
  width: 100%;
  height: 100%;
}
.container {
  // background-color: $primaryColor;
  background: #fff;
  width: 100%;
  padding: 30px;
  padding-bottom: 30px;
  overflow: auto;
}
.fullContainer {
  // background-color: $primaryColor;
  background: #fff;
  width: 100%;
  height: 100%;
}
