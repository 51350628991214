@import "../styles/variables.scss";

/* @media (min-width: 300px) {
.container{
width: 100%;
border: 2px solid red;
}
} */
.layout{
    background-color: $primaryColor;
    // height:calc(100vh + 60px) !important;
    // max-height: 100%;
    height: 100vh;

}
.container{
    width: 100%;
    max-width: 900px;
    height:100%;
    // min-height: 680px;
    // max-width: 100%;
    // max-height: 100%;
    margin: auto;
    position: relative;
    top: 0;
    left: 0;
    overflow: auto;
    
}
