@import "../../styles//variables.scss";
.outletContainer {
  padding: 20px;
  border: 1px solid gray;
  cursor: pointer;
}
.container {
  @include contentCenter;
  width: 100%;
  flex-direction: column;
}

.outletCard {
  @include contentCenter;
  border: 1px solid grey;
  padding: 20px;
  width: 80%;
  color:white;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 10px;
}

.heading {
  font-size: $xlargeFont;
  font-weight: $mediumWeight;
  margin-bottom: 20px;
  color:white
}
