@import "../../styles/variables.scss";


.authContainer {
  background-color: $primaryColor;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
}

.header {
  width: 100%;
  height: 85px;
  margin-top: 60px;
  display: flex;
  align-items: center;
  object-fit: contain;
}
.container {
  display: flex;
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 40px;

}

@media #{$media} and ($maxWidth: $sm) {
  .mainImg {
    display: none;
  }

  .authContainer {
    width: 100%;
    padding: 20px;
  }

}
