@import "../../styles/variables.scss";
.labelContainer {
  @include contentCenter;
  padding-bottom: 15px;
  justify-content: flex-start;
  font-family: "Poppins", serif !important;
}
.backArrow {
  margin-right: 10px;
}
.textFieldContainer {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-self: stretch;
  font-family: "Poppins", serif !important;
}

@mixin input {
  border: 2px solid #ffac33;
  background-color: white;
  border-radius: 4px;
  padding: 0 7px;
  box-shadow: none;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins", serif !important;

  fieldset {
    border: none;
    box-shadow: none;
  }
}

.inputBox {
  @include input;
  height: 60px;
}
.multilineInputBox {
  height: unset;
  font-family: "Poppins", serif !important;
}
.labelText {
  @include regularText;
  font-size: $mediumFont;
  margin: $xsmallMargin;
  display: inline-block;
  text-align: left;
  color: #111111;
  font-size: 32px;
  margin-bottom: 10px;
  font-family: "Poppins", serif !important;
}

.errorMessage {
  @include regularText;
  color: $buttonColor;
  margin-top: 5px;
  font-size: $regularFont;
  font-family: "Poppins", serif !important;
}

@media #{$media} and ($maxWidth: $sm) {
  .labelText {
    font-size: 20px;
  }
}
