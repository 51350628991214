@import "../../styles/variables.scss";

.mainContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.heading {
  font-weight: $mediumWeight;
  color:white;
  font-size: $largeFont;
}

.inputContainer {
  margin-top: 30px;
}

.signInButton {
  width: 100%;
  padding: 10px 0;
  margin-top: 20px;
}

.signupHeading {
  font-size: $largeFont;
  margin-top: $regularMargin;
  cursor: pointer;
  span {
    display: inline-block;
    color: $primaryColor;
  }
}

.agreeText {
  width: 80%;
  font-size: $regularFont;
  line-height: 18px;
}

.conditionText {
  text-decoration: underline;
  margin-left: 3px;
  margin-right: 3px;
  text-decoration: underline;
}

@media #{$media} and ($maxWidth: $sm) {
  .agreeText {
    width: 100%;
  }
}
